<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="6"
        ><base-selector
          v-model="search"
          :items="partsCategories"
          label="Name (*)"
          placeholder="Back"
          clearable
          @input="filterItemProducts"
      /></v-col>
    </v-row>
    <ItemUnitsOnProductEditFormTable ref="table" />
  </div>
</template>

<script>
import { PARTS_CATEGORIES, INVENTORY_CATEGORIES } from '@/common/terms'
import ItemUnitsOnProductEditFormTable from './ItemUnitsOnProductEditFormTable'
export default {
  components: {
    ItemUnitsOnProductEditFormTable
  },
  data() {
    return {
      search: null,
      items: [],
      partsCategories: [...PARTS_CATEGORIES, ...INVENTORY_CATEGORIES]
    }
  },
  methods: {
    filterItemProducts(name) {
      this.$refs.table.fetchData(name)
    }
  }
}
</script>

<style lang="scss" scoped></style>
