<template>
  <v-data-table
    id="product-items-table"
    :headers="headers"
    :items="clientEndProducts"
    :loading="isLoading"
    item-key="id"
    loader-height="1"
    :page.sync="options.page"
    :server-items-length="meta.totalPages"
    @click:row="$emit('click:row', $event)"
  >
    <template v-slot:item.attributes.name="{ item }">
      {{ item.attributes.name | titleCase }}
    </template>

    <template v-slot:item.customName="{ item }">
      {{
        item.attributes.customAttributes &&
          item.attributes.customAttributes.customName | titleCase
      }}
    </template>

    <template v-slot:item.dimensions="{ item }">
      <ul>
        <li v-if="item.attributes.dimensions.length">
          <span class="text-caption grey--text"> L</span>
          {{
            toMeters(
              item.attributes.dimensions.length,
              item.attributes.dimensionsUnit
            ) || '·'
          }}
          m
        </li>
        <li v-if="item.attributes.dimensions.width">
          <span class="text-caption grey--text"> W </span>
          {{
            toCentimeters(
              item.attributes.dimensions.width,
              item.attributes.dimensionsUnit
            ) || '·'
          }}
          cm
        </li>
        <li v-if="item.attributes.dimensions.height">
          <span class="text-caption grey--text"> T </span>
          {{
            toMillimeters(
              item.attributes.dimensions.height,
              item.attributes.dimensionsUnit
            ) || '·'
          }}
          mm
        </li>

        <li v-if="item.attributes.volume">
          <span class="grey--text"> Vol. </span>
          {{ item.attributes.volume || '·' }}
          <span
            v-if="item.attributes.volume && item.attributes.volumeUnit !== 'm³'"
            >{{ item.attributes.volumeUnit }}</span
          >
        </li>
      </ul>
    </template>
    <template v-slot:item.quantity="{ item }">
      <QuantityField
        :maximum="true"
        :units-on-order="true"
        dense
        filled
        :item="item"
      />
    </template>
    <template v-slot:footer>
      <v-pagination
        v-model="options.page"
        light
        circle
        class="py-4"
        :length="meta.totalPages"
        total-visible="15"
        @input="onPagination"
      ></v-pagination>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
import QuantityField from '@/components/QuantityField'
import { UnitsConversion } from '@/mixins/units.conversion.mixin'
export default {
  name: 'ClientEndProductsIndexTable',
  components: { QuantityField },
  mixins: [UnitsConversion],
  data() {
    return {
      isLoading: true,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'attributes.name'
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'attributes.description'
        },
        {
          text: 'Specie',
          value: 'attributes.customAttributes.specie.text',
          sortable: false
        },
        {
          text: 'Dimensions',
          sortable: false,
          align: 'end',
          value: 'dimensions'
        },
        {
          text: 'In Stock',
          sortable: false,
          value: 'attributes.itemUnitsInStockCount'
        },
        {
          text: 'Quantity',
          sortable: false,
          align: 'center',
          value: 'quantity'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      clientEndProducts: 'clientEndProduct/items',
      meta: 'clientEndProduct/meta',
      options: 'clientEndProduct/options'
    })
  },

  created() {
    this.fetchData()
    this.$store.dispatch('clientEndProduct/setPagination', {
      page: 1,
      itemsPerPage: 10
    })
  },

  methods: {
    async fetchData(name) {
      this.isLoading = true
      await this.filterClientEndProducts(name)
      this.isLoading = false
    },
    async onPagination() {
      this.isLoading = true
      this.$store.dispatch('clientEndProduct/setPagination', this.options)
      this.$vuetify.goTo('#product-items-table', 'easeInOutCubic')
      await this.filterClientEndProducts()
      this.isLoading = false
    },
    async filterClientEndProducts(name) {
      return await this.$store.dispatch('clientEndProduct/filter', {
        organizationId: ORGANIZATION_ID(),
        'page[number]': this.options.page,
        'page[size]': this.options.itemsPerPage,
        sort: '-updated_at',
        name
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
