<template>
  <base-form-layout
    title="Select Part (Inventory)"
    size="large"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <ItemUnitsOnProductEditForm />
    </v-form>
  </base-form-layout>
</template>

<script>
import ItemUnitsOnProductEditForm from './components/ItemUnitsOnProductEditForm'
import { ORGANIZATION_ID } from '@/common/config.js'
import { ITEM_UNIT_ON_ORDER } from '@/models/item.unit'
import { FormMixin } from '@/mixins/form.mixin'
import { DocumentMixin } from '@/mixins/document.mixin.js'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'ItemUnitOnProductEdit',
  components: {
    ItemUnitsOnProductEditForm
  },
  mixins: [FormMixin, DocumentMixin],
  props: {
    itemProductId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      itemUnitOnProduct: ITEM_UNIT_ON_ORDER(),
      clientId: null,
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      // currentUserId: 'auth/currentUserId',
      clientEndProducts: 'clientEndProduct/items'
    })
  },
  async created() {
    await this.fetchData()
  },

  methods: {
    ...mapActions({
      addNotification: 'notification/add'
    }),
    async fetchData() {
      this.isLoading = true
      this.itemUnitOnProduct.attributes.productId = this.itemProductId
      this.isLoading = false
    },

    async onSubmit() {
      this.beforeSubmit = false

      try {
        for await (const item of this.clientEndProducts) {
          const {
            id,
            attributes: { quantity }
          } = item
          if (!quantity) continue
          this.itemUnitOnProduct.id = id
          this.itemUnitOnProduct.attributes.quantity = quantity
          this.itemUnitOnProduct.attributes.organizationId = ORGANIZATION_ID()
          // this.itemUnitOnProduct.attributes.clientId = this.currentUserId
          await this.createitemUnitOnProduct(this.itemUnitOnProduct)
        }
        this.addNotification([{ status: '200', title: `Successfully created` }])
        this.$router.go(-1)
      } catch (error) {
        if (error.response)
          return this.addNotification(error.response.data.errors)
      }
    },
    async createitemUnitOnProduct(itemUnitOnProduct) {
      await this.$store.dispatch('itemUnit/update', {
        data: itemUnitOnProduct,
        attribute: 'units-on-product'
      })
    }
  }
}
</script>
